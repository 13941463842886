.toast-container {
  width: 100%;
  position: relative;
  margin: 0 auto;
}

.toast {
  position: fixed;
  max-width: 300px;
  width: 100%;
  top: -200px;
  height: 0;
  padding: 0;
  right: 0;
  overflow: hidden;
  text-align: center;
  background-color: white;
  z-index: 40;
}

.toast.active {
  height: auto;
  padding: 20px;
  animation: 0.4s myAnimation linear;
  animation-fill-mode: forwards;
  border-radius: 8px;
  margin-right: 1rem;
}

.toast.success {
  color: rgba(0, 128, 0, 0.7);
  border: 1px solid rgba(0, 128, 0, 0.7);
}

.toast.error {
  color: rgba(255, 0, 0, 0.7);
  border: 1px solid rgba(255, 0, 0, 0.7);
}

.contact-form-section .toast {
  right: 50%;
  transform: translateX(50%);
}

.publisher-contact-form form .toast-container .toast {
  right: 50%;
  transform: translateX(50%);
}
@keyframes myAnimation {
  0% {
    top: -200px;
  }
  100% {
    top: 10px;
  }
}
